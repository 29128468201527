import React, { useState } from 'react';
import { Text2 } from '@el8/vital';

import { VitalHRNodeAttributes } from 'EntityTypes';
import { TypedNodeViewProps } from 'utils/tiptap';

import VisitNote2Block from '../VisitNote2Block';
import VitalHRNodeForm from './VitalHRNodeForm';
import { useNote2VitalsCollection } from './helpers';
import { extractNodeViewProps } from '../../../helpers';

export type VitalHRNodeProps = TypedNodeViewProps<VitalHRNodeAttributes>;

/**
 * A Note 2.0 custom node for viewing and entering heart rate vitals information.
 */
function VitalHRNode(props: VitalHRNodeProps): JSX.Element {
  const [busy, setBusy] = useState(false);
  const nodeViewProps = extractNodeViewProps(props);
  const { editor, node, updateAttributes } = props;

  const vitalsCollectionId = node.attrs.vitals_collection?.id;

  const {
    data: vitalsCollection,
    isLoading,
    isFetching,
  } = useNote2VitalsCollection(editor, vitalsCollectionId);

  return (
    <VisitNote2Block
      {...nodeViewProps}
      label="Heart Rate"
      tagColor="var(--vds-gray-100)"
      aria-busy={busy || isLoading || isFetching}
    >
      {editor.isEditable ? (
        <VitalHRNodeForm
          node={node}
          updateAttributes={updateAttributes}
          vitalsCollection={vitalsCollection}
          onBusyChange={setBusy}
        />
      ) : (
        <Text2 contentEditable={false} sizing="sm">
          {node.attrs.vnihr?.description}
        </Text2>
      )}
    </VisitNote2Block>
  );
}

export default VitalHRNode;
