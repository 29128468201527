import React from 'react';
import { Text2 } from '@el8/vital';

import { VitalWeightNodeAttributes } from 'EntityTypes';
import { TypedNodeViewProps } from 'utils/tiptap';

import VisitNote2Block from '../VisitNote2Block';
import VitalWeightNodeForm from './VitalWeightNodeForm';
import { useNote2VitalsCollection } from './helpers';
import { extractNodeViewProps } from '../../../helpers';

export type VitalWeightNodeProps = TypedNodeViewProps<VitalWeightNodeAttributes>;

/**
 * A Note 2.0 custom node for viewing and entering weight vitals information.
 */
function VitalWeightNode(props: VitalWeightNodeProps): JSX.Element {
  const nodeViewProps = extractNodeViewProps(props);
  const { editor, node, updateAttributes } = props;

  const vitalsCollectionId = node.attrs.vitals_collection?.id;

  const { data: vitalsCollection } = useNote2VitalsCollection(editor, vitalsCollectionId);

  return (
    <VisitNote2Block {...nodeViewProps} label="Weight" tagColor="var(--vds-gray-100)">
      {editor.isEditable ? (
        <VitalWeightNodeForm
          node={node}
          updateAttributes={updateAttributes}
          vitalsCollection={vitalsCollection}
        />
      ) : (
        <Text2 contentEditable={false} sizing="sm">
          {node.attrs.vniweight?.description}
        </Text2>
      )}
    </VisitNote2Block>
  );
}

export default VitalWeightNode;
