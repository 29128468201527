import React from 'react';
import { Text2 } from '@el8/vital';

import { TypedNodeViewProps } from 'utils/tiptap';
import { useCurrentPatient } from 'modules/patient';
import { useGetVitalsCollection } from 'modules/patient-vitals';
import { VitalBMINodeAttributes } from 'EntityTypes';

import VisitNote2Block from '../VisitNote2Block';
import VitalBMINodeForm from './VitalBMINodeForm';
import { extractNodeViewProps } from '../../../helpers';

export type VitalBMINodeProps = TypedNodeViewProps<VitalBMINodeAttributes>;

/**
 * A Note 2.0 custom node for viewing BMI vitals information.
 */
function VitalBMINode(props: VitalBMINodeProps): JSX.Element {
  const nodeViewProps = extractNodeViewProps(props);
  const { editor, node, updateAttributes } = props;

  const vitalsCollectionId = node.attrs.vitals_collection?.id;
  const [currentPatient] = useCurrentPatient();
  const { data: vitalsCollection } = useGetVitalsCollection(currentPatient?.id, vitalsCollectionId);

  const bmi = vitalsCollection?.bmi || '';
  return (
    <VisitNote2Block {...nodeViewProps} label="BMI" tagColor="var(--vds-gray-100)">
      {editor.isEditable ? (
        <VitalBMINodeForm node={node} updateAttributes={updateAttributes} bmiVital={bmi} />
      ) : (
        <Text2 contentEditable={false} sizing="sm">
          {bmi}
        </Text2>
      )}
    </VisitNote2Block>
  );
}

export default VitalBMINode;
