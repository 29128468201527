import React from 'react';
import { Text2 } from '@el8/vital';

import { VitalHeightNodeAttributes } from 'EntityTypes';
import { TypedNodeViewProps } from 'utils/tiptap';

import VisitNote2Block from '../VisitNote2Block';
import VitalHeightNodeForm from './VitalHeightNodeForm';
import { useNote2VitalsCollection } from './helpers';
import { extractNodeViewProps } from '../../../helpers';

export type VitalHeightNodeProps = TypedNodeViewProps<VitalHeightNodeAttributes>;

/**
 * A Note 2.0 custom node for viewing and entering height vitals information.
 */
function VitalHeightNode(props: VitalHeightNodeProps): JSX.Element {
  const nodeViewProps = extractNodeViewProps(props);
  const { editor, node, updateAttributes } = props;

  const vitalsCollectionId = node.attrs.vitals_collection?.id;

  const { data: vitalsCollection } = useNote2VitalsCollection(editor, vitalsCollectionId);

  return (
    <VisitNote2Block {...nodeViewProps} label="Height" tagColor="var(--vds-gray-100)">
      {editor.isEditable ? (
        <VitalHeightNodeForm
          node={node}
          updateAttributes={updateAttributes}
          vitalsCollection={vitalsCollection}
        />
      ) : (
        <Text2 contentEditable={false} sizing="sm">
          {node.attrs.vniheight?.description}
        </Text2>
      )}
    </VisitNote2Block>
  );
}

export default VitalHeightNode;
