import { TextInputFormik } from '@el8/vital-formik';
import React from 'react';
import { Group } from '@el8/vital';
import noop from 'lodash/noop';
import { FormikProps, withFormik, WithFormikConfig } from 'formik';

import { VitalsCollection } from 'EntityTypes';
import { NumberInputFormik } from 'components/forms';
import { VITALS_EXTRA_NOTE_MAX_LENGTH } from 'modules/visit-notes';
import { alertError } from 'utils/errors';
import useAppFormikContext from 'utils/forms/useAppFormikContext';

import { VitalTemperatureNodeProps } from './VitalTemperatureNode';
import { useSaveNote2Vital } from './helpers';

type Values = {
  temperature: number | null;
  note: string;
};

interface NonFormikProps extends Pick<VitalTemperatureNodeProps, 'node' | 'updateAttributes'> {
  vitalsCollection: VitalsCollection | undefined;
}

interface Props extends NonFormikProps, FormikProps<Values> {}

const withFormikConfig: WithFormikConfig<NonFormikProps, Values> = {
  enableReinitialize: true,
  mapPropsToValues({ vitalsCollection }) {
    return {
      temperature: vitalsCollection?.temperature ? Number(vitalsCollection.temperature) : null,
      note: vitalsCollection?.temp_note || '',
    };
  },
  handleSubmit: noop,
};

/**
 * A form for creating temperature vitals in a `VitalTemperatureNode`.
 */
function VitalTemperatureNodeForm({ node, updateAttributes }: Props): JSX.Element {
  const { dirty, values } = useAppFormikContext<Values>();

  const vitalId = node.attrs.vnitemperature?.id;

  const saveTemperatureVital = useSaveNote2Vital('temperature', vitalId, {
    updateReferencedEntities: (vital, attributes) => {
      let description = `${vital.temperature} °${vital.units.toUpperCase()}`;
      if (vital.extra_note) {
        description += ` -- ${vital.extra_note}`;
      }

      updateAttributes({
        vnitemperature: { id: vital.id, description },
        ...attributes,
      });
    },
  });

  const handleBlur = async (): Promise<void> => {
    if (dirty) {
      try {
        await saveTemperatureVital({
          temperature: values.temperature != null ? String(values.temperature) : '',
          extra_note: values.note,
        });
      } catch (err) {
        alertError(err);
      }
    }
  };

  return (
    <Group contentEditable={false} verticallyAlign="center">
      <NumberInputFormik
        aria-label="Temperature"
        mode="decimal"
        name="temperature"
        onBlur={handleBlur}
        placeholder="°F"
        style={{ width: 60 }}
      />
      <TextInputFormik
        aria-label="Note"
        maxLength={VITALS_EXTRA_NOTE_MAX_LENGTH}
        name="note"
        onBlur={handleBlur}
        placeholder="note..."
        style={{ flex: 1 }}
      />
    </Group>
  );
}

export default withFormik(withFormikConfig)(VitalTemperatureNodeForm);
