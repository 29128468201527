import { VitalTemperatureNodeAttributes, VitalTemperatureNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalTemperatureNode, { VitalTemperatureNodeProps } from './VitalTemperatureNode';

const VitalTemperatureExtension = createCustomNode<
  VitalTemperatureNodeProps,
  VitalTemperatureNodeAttributes
>(VisitNote2NodeName.VitalTemperature, VitalTemperatureNode, {
  group: 'block',

  atom: true,

  addAttributes() {
    return {
      vnitemperature: {
        default: null,
        ...createAttributeHTMLHandlers('vnitemperature'),
      },
      vitals_collection: {
        default: null,
        ...createAttributeHTMLHandlers('vitals_collection'),
      },
    };
  },
});

/**
 * Returns the node data for a new `VitalTemperatureNode`.
 */
export function createVitalTemperatureNodeData(): VitalTemperatureNodeData {
  return {
    type: VisitNote2NodeName.VitalTemperature,
    attrs: {
      vnitemperature: null,
      vitals_collection: null,
    },
  };
}

export default VitalTemperatureExtension;
