import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { VitalName, VitalNameToModel } from 'EntityTypes';
import {
  AppQueryConfig,
  CreateVitalRequestBody,
  CreateVitalResponseBody,
  GetVitalsCollectionResponseBody,
  UpdateVitalRequestBody,
} from 'QueryTypes';
import { getQueryUrl, jsonFetch } from 'utils/react-query';

const urls = {
  latestVitals(patientId: number): string {
    return `/patient/${patientId}/latest-vitals/`;
  },
};

const queryKeys = {
  /**
   * Returns the query key for interacting with a specific `VitalsCollection`.
   */
  vitalsCollectionDetail: (patientId: MaybeNullishId, collectionId: MaybeNullishId): QueryKey => [
    'patient',
    patientId,
    'vitals-collections',
    collectionId,
  ],
  /**
   * Returns the query key for creating a new vital of a specific type (e.g.
   * height, weight, blood pressure).
   */
  vitals: (
    practiceId: MaybeNullishId,
    patientId: MaybeNullishId,
    vitalName: VitalName,
  ): QueryKey => ['practices', practiceId, 'patients', patientId, 'vitals', vitalName],
  /**
   * Returns the query key for interacting with a specific vital, e.g. a specific `VNIBP`
   * (blood pressure vital).
   */
  vitalDetail: (
    practiceId: MaybeNullishId,
    patientId: MaybeNullishId,
    vitalName: VitalName,
    vitalId: MaybeNullishId,
  ): QueryKey => [...queryKeys.vitals(practiceId, patientId, vitalName), vitalId],
};

/**
 * Query config for fetching the latest vitals for a patient.
 *
 * @param {number} patientId - the ID of the patient
 * @param {string|string[]} vitalNames - if this is a string, it should be either
 * a single vital name or a comma-delimited list of vital names. If this is an array,
 * each element of the array should be a single vital name. Supported vital names
 * include (listed here for convenience but source of truth is in the back end):
 *   bp, rr, hr, height, weight, pain, temperature, oxygen, hc
 */
export const latestVitalsQuery = (
  patientId: number,
  vitalNames: VitalName | VitalName[],
): AppQueryConfig => ({
  url: urls.latestVitals(patientId),
  body: {
    q: Array.isArray(vitalNames) ? vitalNames.join(',') : vitalNames,
  },
  transform: (responseJson) => ({
    patientLatestVitals: responseJson,
  }),
  update: {
    patientLatestVitals: (prevLatestVitals, newLatestVitals) => ({
      ...prevLatestVitals,
      byPatientId: {
        ...prevLatestVitals?.byPatientId,
        [patientId]: {
          ...prevLatestVitals?.byPatientId?.[patientId],
          ...newLatestVitals,
        },
      },
    }),
  },
});

/**
 * Fetches and returns the specified `VitalsCollection`.
 */
export const useGetVitalsCollection = (
  patientId: MaybeNullishId,
  collectionId: MaybeNullishId,
  options?: UseQueryOptions<GetVitalsCollectionResponseBody>,
): UseQueryResult<GetVitalsCollectionResponseBody> => {
  return useQuery({
    queryKey: queryKeys.vitalsCollectionDetail(patientId, collectionId),
    enabled: Boolean(patientId && collectionId),
    ...options,
  });
};

/**
 * Returns a mutation to create a vital, e.g. `VNIBP` (blood pressure vital).
 */
export const useCreateVital = <T extends VitalName>(vitalName: T) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: {
      body: CreateVitalRequestBody<T>;
      practiceId: number;
      patientId: number;
    }) => {
      const { body, patientId, practiceId } = variables;
      return jsonFetch<CreateVitalResponseBody<T>>(
        getQueryUrl(queryKeys.vitals(practiceId, patientId, vitalName)),
        { body, method: 'POST' },
      );
    },
    onSuccess: ({ data: vital, vitals_collection }, { practiceId, patientId }) => {
      queryClient.setQueryData(
        queryKeys.vitalDetail(practiceId, patientId, vitalName, vital.id),
        vital,
      );
      if (vitals_collection) {
        queryClient.setQueryData(
          queryKeys.vitalsCollectionDetail(patientId, vitals_collection.id),
          vitals_collection,
        );
      }
      queryClient.invalidateQueries({
        queryKey: queryKeys.vitalsCollectionDetail(patientId, vital.collection_id),
      });
    },
  });
};

/**
 * Returns a mutation to update a vital, e.g. `VNIBP` (blood pressure vital).
 */
export const useUpdateVital = <T extends VitalName>(vitalName: T) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: {
      body: UpdateVitalRequestBody<T>;
      practiceId: number;
      patientId: number;
      vitalId: number;
    }) => {
      const { body, patientId, practiceId, vitalId } = variables;
      return jsonFetch<VitalNameToModel[T]>(
        getQueryUrl(queryKeys.vitalDetail(practiceId, patientId, vitalName, vitalId)),
        { body, method: 'PATCH' },
      );
    },
    onSuccess: (vital, { practiceId, patientId }) => {
      queryClient.setQueryData(
        queryKeys.vitalDetail(practiceId, patientId, vitalName, vital.id),
        vital,
      );
      queryClient.invalidateQueries({
        queryKey: queryKeys.vitalsCollectionDetail(patientId, vital.collection_id),
      });
    },
  });
};
