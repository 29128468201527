import { VitalBMINodeAttributes, VitalBMINodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalBMINode, { VitalBMINodeProps } from './VitalBMINode';

const VitalBMIExtension = createCustomNode<VitalBMINodeProps, VitalBMINodeAttributes>(
  VisitNote2NodeName.VitalBMI,
  VitalBMINode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vnibmi: {
          default: null,
          ...createAttributeHTMLHandlers('vnibmi'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalBMINode`.
 */
export function createVitalBMINodeData(): VitalBMINodeData {
  return {
    type: VisitNote2NodeName.VitalBMI,
    attrs: {
      vnibmi: null,
      vitals_collection: null,
    },
  };
}
export default VitalBMIExtension;
