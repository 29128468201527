import { VitalHeightNodeAttributes, VitalHeightNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalHeightNode, { VitalHeightNodeProps } from './VitalHeightNode';

const VitalHeightExtension = createCustomNode<VitalHeightNodeProps, VitalHeightNodeAttributes>(
  VisitNote2NodeName.VitalHeight,
  VitalHeightNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vniheight: {
          default: null,
          ...createAttributeHTMLHandlers('vniheight'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalHeightNode`.
 */
export function createVitalHeightNodeData(): VitalHeightNodeData {
  return {
    type: VisitNote2NodeName.VitalHeight,
    attrs: {
      vniheight: null,
      vitals_collection: null,
    },
  };
}

export default VitalHeightExtension;
