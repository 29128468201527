import { TextInputFormik } from '@el8/vital-formik';
import React from 'react';
import { Group } from '@el8/vital';
import noop from 'lodash/noop';
import { FormikProps, withFormik, WithFormikConfig } from 'formik';

import { VitalsCollection } from 'EntityTypes';
import { NumberInputFormik } from 'components/forms';
import { VITALS_EXTRA_NOTE_MAX_LENGTH } from 'modules/visit-notes';
import { alertError } from 'utils/errors';
import useAppFormikContext from 'utils/forms/useAppFormikContext';

import { VitalBPNodeProps } from './VitalBPNode';
import { useSaveNote2Vital } from './helpers';

type Values = {
  bpSystolic: number | null;
  bpDiastolic: number | null;
  note: string;
};

interface NonFormikProps extends Pick<VitalBPNodeProps, 'node' | 'updateAttributes'> {
  vital: ArrayElementType<VitalsCollection['bps']> | undefined;
  onBusyChange: (busy: boolean) => void;
}

interface Props extends NonFormikProps, FormikProps<Values> {}

const withFormikConfig: WithFormikConfig<NonFormikProps, Values> = {
  enableReinitialize: true,
  mapPropsToValues({ vital }) {
    return {
      bpDiastolic: vital?.bp_d ? Number(vital.bp_d) : null,
      note: vital?.bp_note || '',
      bpSystolic: vital?.bp_s ? Number(vital.bp_s) : null,
    };
  },
  handleSubmit: noop,
};

/**
 * A form for creating blood pressure vitals in a `VitalBPNode`.
 */
function VitalBPNodeForm({ node, updateAttributes, onBusyChange }: Props): JSX.Element {
  const { dirty, values } = useAppFormikContext<Values>();

  const vitalId = node.attrs.vnibp?.id;

  const saveBPVital = useSaveNote2Vital('bp', vitalId, {
    updateReferencedEntities: (vital, attributes) => {
      let description = `${vital.bp_s}/${vital.bp_d}`;
      if (vital.extra_note) {
        description += ` -- ${vital.extra_note}`;
      }

      updateAttributes({
        vnibp: { id: vital.id, description },
        ...attributes,
      });
    },
  });

  const handleBlur = async (): Promise<void> => {
    if (dirty) {
      try {
        onBusyChange(true);

        await saveBPVital({
          bp_d: values.bpDiastolic != null ? String(values.bpDiastolic) : '',
          bp_s: values.bpSystolic != null ? String(values.bpSystolic) : '',
          extra_note: values.note,
        });
      } catch (err) {
        alertError(err);
      } finally {
        onBusyChange(false);
      }
    }
  };

  return (
    <Group contentEditable={false} verticallyAlign="center">
      <NumberInputFormik
        aria-label="Systolic"
        mode="decimal"
        name="bpSystolic"
        onBlur={handleBlur}
        placeholder="Sys"
        style={{ width: 50 }}
      />
      /
      <NumberInputFormik
        aria-label="Diastolic"
        mode="decimal"
        name="bpDiastolic"
        onBlur={handleBlur}
        placeholder="Dias"
        style={{ width: 50 }}
      />
      <TextInputFormik
        aria-label="Note"
        maxLength={VITALS_EXTRA_NOTE_MAX_LENGTH}
        name="note"
        onBlur={handleBlur}
        placeholder="note..."
        style={{ flex: 1 }}
      />
    </Group>
  );
}

export default withFormik(withFormikConfig)(VitalBPNodeForm);
