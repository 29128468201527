import { TextInputFormik } from '@el8/vital-formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { Group } from '@el8/vital';
import noop from 'lodash/noop';

import { FormikProps, withFormik, WithFormikConfig } from 'formik';
import { VitalsCollection } from 'EntityTypes';
import { VITALS_EXTRA_NOTE_MAX_LENGTH } from 'modules/visit-notes';
import { alertError } from 'utils/errors';
import useAppFormikContext from 'utils/forms/useAppFormikContext';

import { usePrevious } from 'utils/hooks';

import { VitalHeightNodeProps } from './VitalHeightNode';
import { useLatestHeightInitialization, useSaveNote2Vital } from './helpers';
import { Note2VitalContext } from './Note2VitalContextProvider';

type Values = {
  height: string;
  note: string;
};

interface NonFormikProps extends Pick<VitalHeightNodeProps, 'node' | 'updateAttributes'> {
  vitalsCollection: VitalsCollection | undefined;
}

interface Props extends NonFormikProps, FormikProps<Values> {}

const withFormikConfig: WithFormikConfig<NonFormikProps, Values> = {
  enableReinitialize: true,
  mapPropsToValues({ vitalsCollection }) {
    return {
      height: vitalsCollection?.height ? vitalsCollection.height : '',
      note: vitalsCollection?.ht_note || '',
    };
  },
  handleSubmit: noop,
};

/**
 * A form for creating height vitals in a `VitalHeightNode`.
 */
function VitalHeightNodeForm({ node, updateAttributes, vitalsCollection }: Props): JSX.Element {
  const { dirty, setFieldValue, values } = useAppFormikContext<Values>();

  const vitalId = node.attrs.vniheight?.id;

  const vitalStore = useContext(Note2VitalContext);

  const initializeLatestHeight = useLatestHeightInitialization();

  const saveHeightVital = useSaveNote2Vital('height', vitalId, {
    updateReferencedEntities: (vital, attributes) => {
      let description = `${vital.height} ${vital.units}`;
      if (vital.extra_note) {
        description += ` -- ${vital.extra_note}`;
      }

      updateAttributes({
        vniheight: { id: vital.id, description },
        ...attributes,
      });
    },
  });

  const initializeToLatestHeight = useCallback(async (): Promise<void> => {
    await initializeLatestHeight(async (height) => {
      setFieldValue('height', height);
      await saveHeightVital({ height, extra_note: '' });
    });
  }, [initializeLatestHeight, saveHeightVital, setFieldValue]);

  useEffect(function defaultToLatestHeightIfNoCollection() {
    if (!node.attrs.vitals_collection?.id) {
      initializeToLatestHeight();
    }
  }, []);

  const prevVitalsCollection = usePrevious(vitalsCollection);
  useEffect(
    function defaultToLatestHeightIfCollectionMissingHeight() {
      if (!prevVitalsCollection && vitalsCollection && !vitalsCollection.height) {
        initializeToLatestHeight();
      }
    },
    [initializeToLatestHeight, prevVitalsCollection, vitalsCollection],
  );

  useEffect(() => {
    if (vitalsCollection && vitalStore) {
      vitalStore.setHeight(vitalsCollection.height ? Number(vitalsCollection.height) : null);
    }
  }, [vitalsCollection, vitalStore]);

  const handleBlur = async (): Promise<void> => {
    if (dirty) {
      try {
        const parsedHeight =
          values.height !== '' ? String(el8.util.string.cleanLength(values.height)) : '';

        await saveHeightVital({
          height: parsedHeight,
          extra_note: values.note,
        });

        setFieldValue('height', parsedHeight);
      } catch (err) {
        alertError(err);
      }
    }
  };

  return (
    <Group contentEditable={false} verticallyAlign="center">
      <TextInputFormik
        aria-label="Height"
        onBlur={handleBlur}
        placeholder="inches"
        name="height"
        style={{ width: 60 }}
      />
      <TextInputFormik
        aria-label="Note"
        maxLength={VITALS_EXTRA_NOTE_MAX_LENGTH}
        name="note"
        onBlur={handleBlur}
        placeholder="note..."
        style={{ flex: 1 }}
      />
    </Group>
  );
}

export default withFormik(withFormikConfig)(VitalHeightNodeForm);
