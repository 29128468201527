import React, { createContext, Dispatch, useState } from 'react';

export type VitalContextType = {
  height: number | null;
  weight: number | null;
  setHeight: Dispatch<number | null>;
  setWeight: Dispatch<number | null>;
};

export const Note2VitalContext = createContext<VitalContextType | null>(null);

const Note2VitalContextProvider: React.FC = ({ children }): JSX.Element => {
  const [height, setHeight] = useState<number | null>(null);
  const [weight, setWeight] = useState<number | null>(null);

  const value = {
    height,
    weight,
    setHeight,
    setWeight,
  };

  return <Note2VitalContext.Provider value={value}>{children}</Note2VitalContext.Provider>;
};

export default Note2VitalContextProvider;
