import { VitalHRNodeAttributes, VitalHRNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalHRNode, { VitalHRNodeProps } from './VitalHRNode';

const VitalHRExtension = createCustomNode<VitalHRNodeProps, VitalHRNodeAttributes>(
  VisitNote2NodeName.VitalHR,
  VitalHRNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vnihr: {
          default: null,
          ...createAttributeHTMLHandlers('vnihr'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalHRNode`.
 */
export function createVitalHRNodeData(): VitalHRNodeData {
  return {
    type: VisitNote2NodeName.VitalHR,
    attrs: {
      vnihr: null,
      vitals_collection: null,
    },
  };
}

export default VitalHRExtension;
