import React, { useState } from 'react';
import { Text2 } from '@el8/vital';

import { VitalBPNodeAttributes } from 'EntityTypes';
import { TypedNodeViewProps } from 'utils/tiptap';

import VisitNote2Block from '../VisitNote2Block';
import VitalBPNodeForm from './VitalBPNodeForm';
import { useNote2VitalsCollection } from './helpers';
import { extractNodeViewProps } from '../../../helpers';

export type VitalBPNodeProps = TypedNodeViewProps<VitalBPNodeAttributes>;

/**
 * A Note 2.0 custom node for viewing and entering blood pressure vitals information.
 */
function VitalBPNode(props: VitalBPNodeProps): JSX.Element {
  const [busy, setBusy] = useState(false);
  const nodeViewProps = extractNodeViewProps(props);
  const { editor, node, updateAttributes } = props;

  const vitalsCollectionId = node.attrs.vitals_collection?.id;
  const vitalId = node.attrs.vnibp?.id;

  const {
    data: vitalsCollection,
    isLoading,
    isFetching,
  } = useNote2VitalsCollection(editor, vitalsCollectionId);
  const vital = vitalsCollection?.bps.find((bp) => bp.id === vitalId);

  return (
    <VisitNote2Block
      {...nodeViewProps}
      label="Blood Pressure"
      tagColor="var(--vds-gray-100)"
      aria-busy={busy || isLoading || isFetching}
    >
      {editor.isEditable ? (
        <VitalBPNodeForm
          node={node}
          updateAttributes={updateAttributes}
          vital={vital}
          onBusyChange={setBusy}
        />
      ) : (
        <Text2 contentEditable={false} sizing="sm">
          {node.attrs.vnibp?.description}
        </Text2>
      )}
    </VisitNote2Block>
  );
}

export default VitalBPNode;
