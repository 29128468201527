import { RootState } from 'StoreTypes';
import { HeightVital, WeightVital } from 'EntityTypes';

// get the patient's latest height vitals if it exists
export const getLatestHeight = (state: RootState, patientId: number): HeightVital =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'HeightVital | undefined' is not assignable t... Remove this comment to see the full error message
  state.entities.patientLatestVitals?.byPatientId?.[patientId]?.height;

// get the patient's latest weight vitals if it exists
export const getLatestWeight = (state: RootState, patientId: number): WeightVital =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'WeightVital | undefined' is not assignable t... Remove this comment to see the full error message
  state.entities.patientLatestVitals?.byPatientId?.[patientId]?.weight;
